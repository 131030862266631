.map_wrapper {
  position: relative;
  width: 330px;
  left: 50%;
  transform: translateX(-50%);

  .address_in_map {
    position: relative;
    left: 90px;
    top: -110px;
    text-align: left;
    font-weight: bold;

    .company_name {
      color: #67BDED;
      text-transform: uppercase;
    }
  }
}