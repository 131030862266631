/* change the colors */
$primary: #27ade4;
$secondary: #EBEBEB;
$dark: #0B7DBD;
$info: #27ade4;
@import "../../node_modules/bootstrap";
@import "./aboutUs";
@import "./contact_address";

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'PtSansCaption';
  src: local('PtSansCaption'), url("../fonts/PT_Sans-Caption-Web-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'OpenSans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-primary {
  color: white;
}

.bg-primary {
  color: white;
}

.bg-dark {
  color: white;
}

.bg-secondary {
  background-color: $secondary;
}

.app-menu-phone {
  color: #67BDED;
  font-size: calc(0.8rem + 0.2vw);

  img {
    //width: 100px;
    height: 30px;
  }

  /* sm */
  @media (min-width: 992px) {
    font-size: calc(1.1rem + 0.4vw);

    img {
      height: 55px;
    }
  }
}

.right {
  float: right !important;
}

.app-menu-navigation {
  font-weight: bold;
  @media (max-width: 576px) {
    //position: absolute;
    //top: 1.75rem;
    //left: 1.75rem
    float: right !important;
  }
}

#responsive-navbar-nav{
  background-color: hsla(0, 0%, 100%, 0.8);
}

.nav-link {
  color: #050505 !important;
  font-size: calc(1.1rem + 0.4vw);
}

.nav-item {
  cursor: pointer;
  color: #050505 !important;
  font-size: calc(1.1rem + 0.4vw);
  @media (min-width: 992px){
    padding: 0.5rem 1rem;
  }
}

.app-logo {
  pointer-events: none;
  /* xs */
  img {
    //width: 100px;
    height: 35px;
  }

  /* sm */
  @media (min-width: 992px) {
    img {
      height: 55px;
    }
  }

  //@media (max-width: 576px) {
  //  position: absolute;
  //  top: 1.75rem;
  //  right: 1.75rem
  //}
}

.app-menu {
  //@media (max-width: 576px) {
  //  nav {
  //    position: absolute;
  //    top: 1.75rem;
  //    right: 1.75rem
  //  }
  //}

  min-height: 10vh;

  .container {
    position: relative;
  }
;
}

.app-body {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

.ml-auto {
  margin-left: auto !important;
}


.clickable :hover {
  background-color: $light;

  img:hover {
    opacity: .5;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
  }
}

.clickable {
  opacity: 1;
  cursor: pointer;
}

input {
  //color: $secondary;
  background-color: white;
  width: 100%;
  border: none;
}

.font-weight-bold {
  font-weight: bold;
}

.half-width {
  max-width: 50%;
}