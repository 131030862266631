.about_us_wrap{
  overflow: hidden;
}
.about_us {
  position: relative;
  overflow: hidden;
  .img-fluid{
    width: 150%;
    max-width: 150%;
  }

  /* sm */
  @media (min-width: 992px) {
    .img-fluid{
      width: 100%;
      max-width: 100%;
    }
  }
}

.about_us_box {
  color: white;
  background: #67BDED;

  @media (min-width: 1200px) {
    position: absolute;
    width: 579px;
    left: 57px;
    top: 221px;
  }

  @media (min-width: 530px) and (max-width: 1200px) {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 50px;
    button: 50px;
  }

  @media (max-width: 530px) {
    left: 50px;
    right: 50px;
    top: 50px;
    button: 50px;
  }


  button:hover {
    color: #67BDED83
  }

  button {
    color: #67BDED;
    font-family: 'PtSansCaption';
  }
}